export default {
  en: {
    'required': () => `This field is required.`,
    'regex': ({parameter}) => `This field does not have the required format (${parameter}).`,
    'min':  ({parameter}) => `This field requires at least ${parameter} characters.`,
    'max': ({parameter}) => `This field has a maximum of ${parameter} characters.`,
    'in_array': ({parameter}) => `This field needs to have one of the following values: "${parameter.split(',').join('", "')}".`,
    'is_email': 'This field should contain a valid email address.',
    'equals': 'The expected input was not equal to the received input.',
    'selected': () => "This option should have been selected.",
    'unselected': () => "This option should not have been selected.",
    'correct': () => "This is the correct option.",
    'incorrect': () => "This is not the correct option.",
  },
  nl: {
    'required': 'Dit veld is verplicht.',
    'regex': ({parameter}) => `Dit veld voldoet niet aan de gestelde eisen (${parameter}).`,
    'min': ({parameter}) => `Dit veld moet minimaal ${parameter} tekens bevatten.`,
    'max': ({parameter}) => `Dit veld bevat meer dan ${parameter} tekens.`,
    'in_array': ({parameter}) => `Dit veld moet één van de volgende opties zijn: "${parameter.split(',').join('", "')}".`,
    'is_email': () => `Dit veld dient een geldig email adres te bevatten.`,
    'equals': () => 'De ingevulde waarde was niet gelijk aan de verwachtte waarde.',
    'selected': () => "Deze had aangevinkt moeten zijn.",
    'unselected': () => "Deze had niet aangevinkt moeten zijn.",
    'correct': () => "Dit is de juiste optie.",
    'incorrect': () => "Deze optie is incorrect.",
  }
};
