import React from 'react';
import classNames from 'classnames/bind';
import styles from './DragLayer.module.css';
import {useDragLayer} from "react-dnd";

const cx = classNames.bind(styles);

const DragLayer = props => {

  const {item, currentOffset} = useDragLayer(monitor => ({
    item: monitor.getItem(),
    isDragging: !!monitor.isDragging(),
    currentOffset: monitor.getSourceClientOffset(),
  }));
  const Preview = item && item.preview;
  return (
    <div className={cx('base', 'is-dragging')}>
      <div style={currentOffset ? {transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`} : {}}>
        {Preview}
      </div>
    </div>
  ) ;
};

export default DragLayer;
