import React, {forwardRef} from 'react';
import styles from './Video.module.css';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const Video = forwardRef((props, ref) => {

  const sources = Array.isArray(props.src) ? props.src : [props.src];
  const types = Array.isArray(props.type) ? props.type : [props.type];
  if (props.inline && sources.length !== types.length) {
    throw Error('[VideoComponent] For each source a type must be supplied.');
  }

  const [widthRatio, heightRatio] = Array.isArray(props.ratio) ? props.ratio : props.ratio.split(':');
  const ratioPercentage = (heightRatio / widthRatio) * 100;

  return (
    <div className={cx('base',props.className)} style={{paddingBottom: `${ratioPercentage}%`, ...props.style}}>
      {props.inline ? (
        <video
          ref={ref}
          controls={props.controls}
          autoPlay={props.autoplay}
          loop={props.loop}
          preload={props.preload}
          poster={props.poster}>

          {sources.map((source, idx) => (
            <source key={idx} src={source} type={types[idx]}/>
          ))}

          {props.fallback}
        </video>
      ) : (
        <iframe src={props.src} ref={ref} title={props.title} frameBorder={0} allowFullScreen={props.fullscreen}/>
      )}
    </div>
  );
});

Video.defaultProps = {
  controls: true,
  autoplay: false,
  loop: false,
  preload: 'auto',
  poster: null,
  src: "",
  type: "",
  inline: false,
  title: "",
  fullscreen: true,
  ratio: [16,9],
};

Video.propTypes = {
  controls: PropTypes.bool,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  preload: PropTypes.oneOf(['none', 'metadata', 'auto']),
  poster: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  inline: PropTypes.bool,
  title: PropTypes.string,
  fullscreen: PropTypes.bool,
  ratio: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.string]),
};


export default Video;
