import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import FormContext from "./FormContext";

const inputTypes = {
  'text': ['text','email','password','phone','color','date','datetime-local','month','number','search','tel','time','url','week', 'textarea'],
  'none': ['none'],
};

/**
 * This hook connects an input field to the universal "Form" component.
 * It sets the event listeners when appropriate, calculates whether the element is active/inactive and more.
 * @param elementName {string}
 * @param element {MutableRefObject}
 * @param rules {string|array}
 * @param autoConnect {boolean}
 * @returns Object
 */
export default function (elementName,  element , rules, autoConnect = true) {

  const {initialize, connect, values, update, fields, feedback, messages, disabled, exists} = useContext(FormContext);
  const shouldFormConnect = (elementName !== "" && !!elementName && exists);
  const isArray = useMemo(() => /\[]$/.test(elementName), [elementName]);
  const name = isArray ? elementName.slice(0,-2) : elementName;

  const identifier = !element.current.id ? name : isNaN(element.current.id) ? element.current.id : `${name}_${element.current.id}`;
  const [id,setId] = useState(identifier);
  useEffect(() => {
    if(id !== identifier){
      setId(identifier);
    }
  }, [identifier, id]);

  const [active, setActiveState] = useState(false);

  const formValue = shouldFormConnect && values[name];
  const field = (fields && fields.find(field => field.id === id)) || {};

  // Initialize element to form when value is set
  useEffect(() => {
    if(shouldFormConnect){
      initialize(id, name, element.current.value, rules, isArray);
    }
  }, [id, shouldFormConnect, element, name, isArray, rules, initialize]);

  // Once initialized, connect the form
  useEffect(() => {
    if(shouldFormConnect && element && element.current && field.name){
      connect(id, autoConnect ? element.current.value : undefined);
    }
  }, [shouldFormConnect, id, autoConnect, element, field, connect]);


  // Create event handlers
  const handleChange = useCallback(inputValue => {
    const value = typeof inputValue === 'string' ? inputValue : element.current.value;
    if(shouldFormConnect && (value !== formValue || isArray)){
      update(id, value);
    }
  }, [isArray, id, element, formValue, update, shouldFormConnect]);

  // Set appropriate event listeners to handle form inputs
  useEffect(() => {
    if(shouldFormConnect && element) {
      const input = element.current;
      if (!input) return;

      if (inputTypes.text.includes(input.type)) {
        input.addEventListener('keyup', handleChange);
      }
      if (!inputTypes.none.includes(input.type)) {
        input.addEventListener('change', handleChange);
      }

      return () => {
        if (inputTypes.text.includes(input.type)) {
          input.removeEventListener('keyup', handleChange);
        }
        if (!inputTypes.none.includes(input.type)) {
          input.removeEventListener('change', handleChange);
        }
      };
    }
  }, [shouldFormConnect, element, handleChange]);

  useEffect(() => {
    if(shouldFormConnect && element.current && field.name && values[name]){
      const input = element.current;
      if(inputTypes.text.includes(input.type) && input.value !== values[name]){
        input.value = values[name];
      }else{
        input.checked = values[name] && (values[name] === input.value || (isArray && values[name].includes(input.value)));
        input.selected = values[name] && (values[name] === input.value || (isArray && values[name].includes(input.value)));
      }
    }

  }, [shouldFormConnect, element, values, isArray, field, name]);

  const setActive = useCallback(() => {
    setActiveState(true);
  }, []);
  const setInactive = useCallback(() => {
    setActiveState(false);
  },[]);

  // Set Appropriate event listeners to handle active/inactive styling
  useEffect(() => {
    if(shouldFormConnect && element){
      const input = element.current;
      if(!input) return;
      if (!inputTypes.none.includes(input.type)) {
        input.addEventListener('blur', setInactive);
        input.addEventListener('focus', () => setActive);
      }
      return () => {
        if (!inputTypes.none.includes(input.type)) {
          input.removeEventListener('blur', setInactive);
          input.removeEventListener('focus', setActive);
        }
      };
    }
  }, [shouldFormConnect, element, setActive, setInactive]);

  return {
    identifier: id,
    valid: feedback === 'full' ? field.valid : undefined,
    messages: field.messages || [],
    errors: feedback !== 'off' && (messages[name] || []),
    isActive: active,
    isLoading: disabled,
    isLocked: disabled || field.locked,
    isArray,
    exists,
    triggerUpdate: handleChange,
    hasErrors: messages[name] && messages[name].length,
    hasMessages: messages[name] && messages[name].length,
    value: formValue,
    disabled,
  };
}
