import React, {useEffect} from 'react';
import classNames from 'classnames/bind';
import styles from './ContentModal.module.css';

const cx = classNames.bind(styles);

const ContentModal = ({children, pad, className, style, onClose}) => {

  const handleKeyPress = e => {
     if(e.keyCode === 27){
       if(onClose) onClose();
     }
  };

  useEffect(() => {
    if(onClose){
      document.addEventListener('keyup', handleKeyPress);
    }
    return () => {
      if(onClose){
        document.removeEventListener('keyup', handleKeyPress);
      }
    };
  }, []);

  return (
    <div className={cx('base', {'with-padding': pad} , className)} style={style}>
      {onClose &&
      <button className={cx('btn-close')} onClick={() => (onClose) ? onClose() : null}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"/></svg>
      </button>
      }

      {children}
    </div>
  );
};

export default ContentModal;
