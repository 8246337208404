import React, {useRef} from 'react';
import useFormConnect from '../useFormConnect';

const Selectable = (
  {
    id,
    name,
    selected: defaultSelected,
    value: defaultValue,
    rules,
    required,
    onSelect,
    disabled,
    children,
  }) => {

  const element = useRef({id, type: "radio", name, value: defaultValue, checked: !!defaultSelected});
  const {identifier, errors} = useFormConnect(name, element, rules, !!defaultSelected);

  return (
    <label htmlFor={identifier}>
      <input type="radio" id={identifier} name={name} ref={element} value={defaultValue} defaultChecked={defaultSelected}
             style={{display: "none"}}/>
      {children({selected: element.current.checked, errors})}
    </label>
  );
};

const withSelectability = Component => ({selected, ...props}) => {
  return (
    <Selectable id={props.id}
                required={props.required}
                selected={selected}
                name={props.name}
                value={props.value}
                onSelect={props.onSelect}
                disabled={props.disabled || props.deactivated}>

      {(selectableTrait) => {
        return (
          <Component selectable={true}
                     focusable={!(props.disabled || props.deactivated)} {...selectableTrait} {...props}>
            {props.children}
          </Component>
        );
      }}

    </Selectable>
  );
};

export default withSelectability;
