export default {
  required: value => {
    const valueArray = Array.isArray(value) ? value : [value];
    return valueArray.length > 0 && valueArray.every(val => !(val === undefined || val === '' || val === null));
  },
  regex: (value, parameters) => {
    const valueArray = Array.isArray(value) ? value : [value];
    let customRegex = new RegExp(parameters);
    return valueArray.every(value => customRegex.test(value));
  },
  min: (value, parameters) => {
    const valueArray = Array.isArray(value) ? value : [value];
    let minLength = parseInt(parameters);
    return valueArray.every(val => (val && val.length >= minLength));
  },
  max: (value, parameters) => {
    const valueArray = Array.isArray(value) ? value : [value];
    let maxLength = parseInt(parameters);
    return valueArray.every(val => (val.length <= maxLength));
  },
  in_array: (value, parameters) => {
    const valueArray = Array.isArray(value) ? value : [value];
    let options = parameters.split(',');
    return valueArray.every(val => (options.indexOf(val) !== -1));
  },
  is_email: value => {
    const valueArray = Array.isArray(value) ? value : [value];
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return valueArray.every(val => emailRegex.test(String(val).toLowerCase()));
  },
};
