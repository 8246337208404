import React, {useCallback, useMemo, useState} from 'react';
import styles from './Table.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const clamp = (num, min, max) => {
  return Math.min(Math.max(num, min), max);
};

const Content = ({columns, pageResults, getRowProps}) => (
  <table>
    <thead>
    <tr>
      {columns.map((col, idx) => (
        <th key={idx} style={col.headerStyle} className={col.headerClassName}>
          <div>
            {col.header}
          </div>
        </th>
      ))}
    </tr>
    </thead>
    <tbody>
    {pageResults.map((row, rowIdx) => (
      <tr key={rowIdx} {...getRowProps(row)}>
        {columns.map((col, colIdx) => (
          <td key={colIdx} style={col.style} className={col.className}>
            {col.render ? (
              col.render(row, styles)
            ) : (
              <div>
                {row[col.accessor]}
              </div>
            )}
          </td>
        ))}
      </tr>
    ))}
    </tbody>
  </table>
);

const Pagination = ({current, total, isFirst, isLast, previous, next}) => (
    <div className={styles.footer}>
      <div>
        Page {current} of {total}
      </div>
      <div>
        <button disabled={isFirst} onClick={previous}>
          prev
        </button>

        <button disabled={isLast} onClick={next}>
          next
        </button>
      </div>
    </div>
  );

const Locker = ({locked}) => (
  <div className={styles.overlay}>
    {locked}
  </div>
);

function Table(props) {
  const {
    data,
    columns,
    sort,
    filter,
    paginate,
    style,
    className,
    fallback,
    loading,
    lock,
    alternate,
    children,
    getRowProps,
  } = props;

  // Sort and filter data
  const results = useMemo(() => {
    let _results = data ? [...data] : data;

    // Sort data by string
    if (sort && typeof sort === 'string') {
      _results = _results.sort((a, b) => a[sort].localeCompare(b[sort]));
    }

    // Sort data by function
    if (sort && typeof sort === 'function') {
      _results = _results.sort(sort);
    }

    // Filter data by function
    if (filter && typeof filter === 'function') {
      _results = _results.filter(filter);
    }

    return _results;
  }, [data, sort, filter]);

  // Set current page state
  const [currPage, setCurrPage] = useState(1);

  // Calculate total pages based on results
  const totalPages = useMemo(() => {
    if (!paginate) return 1;

    return Math.ceil(results.length / paginate);
  }, [results, paginate]);

  // Flags
  const hasPagination = totalPages > 1;
  const isFirstPage = currPage === 1;
  const isLastPage = currPage === totalPages;

  // Handle prev page event
  const handlePrevPage = useCallback(() => {
    const prevPage = clamp(currPage - 1, 1, totalPages);

    setCurrPage(prevPage);
  }, [currPage]);

  // Handle next page event
  const handleNextPage = useCallback(() => {
    const nextPage = clamp(currPage + 1, 1, totalPages);

    setCurrPage(nextPage);
  }, [currPage, totalPages]);

  const handleGoToPage = useCallback((page) => {
    setCurrPage(page);
  }, []);

  // Create pagination from results
  const pageResults = useMemo(() => {
    // Convert number to index
    const page = currPage - 1;

    return hasPagination
      ? results.slice(page * paginate, (page + 1) * paginate)
      : results;
  }, [results, paginate, currPage, hasPagination]);

  const content = typeof children === 'function' ? children({
    styles,
    results,
    pageResults,
    pagination: {
      isLast: isLastPage,
      isFirst: isFirstPage,
      current: currPage,
      total: totalPages,
      next: handleNextPage,
      previous: handlePrevPage,
      goTo: handleGoToPage,
    },
    Content: <Content {...props} pageResults={pageResults}/>,
  }) : (
    <React.Fragment>
      {children ?
        <React.Fragment><table>{children}</table></React.Fragment>
        : <Content {...props} pageResults={pageResults} />
      }
      {hasPagination &&  <Pagination
        current={currPage}
        total={totalPages}
        isFirst={isFirstPage}
        isLast={isLastPage}
        previous={handlePrevPage}
        next={handleNextPage}
      />}
    </React.Fragment>);

  return (
    <div className={cx('base', {alternate}, className)} style={style}>
      {loading ? loading : (data && data.length === 0) ? fallback : content}

      {lock && <Locker lock={lock} />}
    </div>
  );
}

Table.defaultProps = {
  data: null,
  columns: [],
  sort: null,
  filter: null,
  paginate: null,
  fallback: 'No data',
  loading: null,
  locked: null,
  style: {},
  className: null,
  getRowProps: () => {
  },
};

export default Table;
