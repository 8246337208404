import React from "react";
import styles from "./Card.module.css";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

function CardDetails(props) {
  const { children, style, className } = props;

  return (
    <div className={cx("details", {}, className)} style={style}>
      {children}
    </div>
  );
}

export default CardDetails;
