import React from 'react';
import styles from './Header.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Header = props => (
    <header className={cx('base', props.className)} style={props.style}>
      {props.children}
    </header>
);

export default Header;
