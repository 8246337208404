import React from 'react';
import useDropArea from "./useDropArea";
import {useDrop} from "react-dnd";

const withDropability = (DropArea) => (props) => {

  const {name, accept, onDrop} = props;

  const {list} = useDropArea(name);

  const [{hovering, droppable}, dropElement] = useDrop({
    accept,
    collect: monitor => ({
      hovering: !!monitor.isOver(),
      droppable: !!monitor.canDrop(),
    }),
    drop: (item, monitor) => {
      if(onDrop) {
        onDrop(item, monitor);
      }
      return {dropArea: name};
    }
  });

  return <DropArea {...props} list={list} hovering={hovering} droppable={droppable} element={dropElement}/>;
};

export default withDropability;
