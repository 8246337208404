import validationRules from './rules';

/**
 * Validates a value based on a set of rules.
 * @param value {any} The value under validation
 * @param rules {string|array} Rules that the value should adhere to
 * @returns {[]}
 */
export default function (value, rules){
  let ruleList = (typeof rules === 'string') ? rules.split('|') : rules || [];

  let errors = [];

  for (let i = 0; i < ruleList.length; i++) {
    let rule = ruleList[i];
    let ruleSegments = rule.split(':');

    if(!validationRules[ruleSegments[0]](value, ruleSegments[1])){
      errors.push({
        message: ruleSegments[0],
        parameter: ruleSegments[1]
      });
    }
  }

  return errors;
}
