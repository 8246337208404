import React from 'react';
import styles from './Footer.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Footer = props => (
    <footer className={cx('base')}>
      {props.children}
    </footer>
);

export default Footer;
