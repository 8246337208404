import {createContext} from "react";

export default createContext({
  index: 0,
  values: {},
  defaultValues: {},
  rules: {},
  message: null,
  messages: {},
  exists: false,
  fields: [],
  defaultFields: [],
});
