import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import classNames from 'classnames/bind';
import styles from './TabList.module.css';

const cx = classNames.bind(styles);

const TabList = props => {

  const {children, speed, onChange, active, tabWidth: tabWidthUnit, className, style} = props;

  const [current, setCurrent] = useState(active || 0);
  const container = useRef(false);
  const timer = useRef(0);
  const interval = useRef(0);
  const scrollDirection = useRef('right');
  const tabWidthInt = useRef(0);
  const scrollSpeed = useRef(1);
  const [scrollable, setScrollable] = useState(false);
  const [offsetX, setOffsetX] = useState(0);
  const [overflowing, setOverflowing] = useState([false, false]);

  // Add event listener for mouse up event (on window to ensure that it is fired)
  // Add event listener for scrolling on the tab element to capture scroll and move sideways
  // Calculates the average tab width in pixels for easy calculations
  useEffect(() => {
    const element = container.current;
    window.addEventListener('mouseup', handleOnMouseUp);
    element.addEventListener('wheel', onScroll);

    tabWidthInt.current = element.scrollWidth / children.length;
    if (element.scrollWidth - element.clientWidth > 0) {
      setScrollable(true);
    }

    return () => {
      window.removeEventListener('mouseup', handleOnMouseUp);
      element.removeEventListener('wheel', onScroll);
    };
  }, []);

  // Scroll method that sets direction and moves the tabbar to appropriate direction
  let scrollStop = false;
  const onScroll = (event) => {
    event.preventDefault();
    if (!scrollStop) {
      scrollSpeed.current = speed * 0.5;
      scrollDirection.current = (event.deltaX < 0) ? 'right' : (event.deltaX > 0) ? 'left' : (event.deltaY < 0) ? 'right' : 'left';
      moveX();
      scrollStop = true;
      setTimeout(() => scrollStop = false, 180);
    }
  };

  // Set the element with the 'active' prop to current
  useEffect(() => {
    if (!active && children) {
      children.forEach((child, idx) => {
        if (child.props && child.props.active) {
          setCurrent(idx);
        }
      });
    }
  }, [children, active]);

  // Moves the tabbar to the current selected item
  useEffect(() => {
    const el = container.current;
    if (scrollable) {
      const activeChild = container.current.children[current];
      const clientOffset = activeChild.offsetLeft - offsetX;

      if (clientOffset <= tabWidthInt.current) {
        setOffsetX(activeChild.offsetLeft - (el.clientWidth - tabWidthInt.current) / 2);
      } else if (clientOffset >= el.clientWidth - tabWidthInt.current) {
        setOffsetX(activeChild.offsetLeft - (el.clientWidth - tabWidthInt.current) / 2);
      }
    }
  }, [container, current]);

  // Actually moves the bar when offsetX is changed. Recalculates whether to show the arrow buttons
  useLayoutEffect(() => {
    const el = container.current;
    if (el) {
      setOverflowing([offsetX > 5, (offsetX < el.scrollWidth - el.clientWidth && el.scrollWidth - el.clientWidth > 5)]);
      container.current.style.transform = `translateX(${-offsetX}px)`;
    }
  }, [container, offsetX]);

  const handleClick = (tabIndex) => {
    setCurrent(tabIndex);
    if (onChange) {
      onChange(tabIndex);
    }
  };

  const handleOnMouseDown = (dir) => {
    scrollDirection.current = dir;
    scrollSpeed.current = speed;
    timer.current = setTimeout(() => {
      interval.current = setInterval(moveX, 100);
    }, 200);
  };

  const moveX = (pixels) => {
    setOffsetX(prevX => {
      const maxTranslateX = container.current.scrollWidth - container.current.clientWidth;
      const tabWidth = pixels ? pixels : tabWidthInt.current;
      const offset = scrollSpeed.current * (scrollDirection.current === 'right' ? tabWidth : -tabWidth);
      const x = Math.max(0, Math.min(prevX + offset, maxTranslateX));
      return x < offset ? 0 : x >= maxTranslateX - offset ? maxTranslateX : x;
    });
  };

  const handleOnMouseUp = () => {
    if (interval.current || timer.current) {
      if (!interval.current) {
        moveX();
      }
      clearTimeout(timer.current);
      timer.current = 0;
      clearInterval(interval.current);
      interval.current = 0;
    }
  };

  return (
    <div className={cx('base', className)} style={{...style}}>
      {overflowing[0] &&
      <button className={cx('arrow', 'left')} onMouseDown={() => handleOnMouseDown('left')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
          <path d="M0 0h24v24H0z" fill="none"/>
        </svg>
      </button>
      }
      <div ref={container} className={cx('tabs')}>
        {children.map((tabContent, index) => (
          <div key={index}
               className={cx('tab', {'is-active': (current === index)})}
               style={{minWidth: tabWidthUnit}}
               onClick={() => handleClick(index)}>
            {tabContent}
          </div>
        ))}
      </div>
      {overflowing[1] &&
      <button className={cx('arrow', 'right')} onMouseDown={() => handleOnMouseDown('right')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
          <path d="M0 0h24v24H0z" fill="none"/>
        </svg>
      </button>
      }
    </div>
  );
};

TabList.defaultProps = {
  speed: 1,
};

export default TabList;
