import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import defaultTranslations from './translations.js';
import FormContext from './FormContext';
import validator from './Validator';
import translator from './Translator';
import PropTypes from 'prop-types';
import useEventHandler from "../../Hooks/useEventHandler";

const Form = (
  {
    action,
    validate: shouldValidate,
    onSubmit,
    onError,
    onSuccess,
    onChange,
    onSave,
    onLoad,
    onRestore,
    onRestored,
    onValidationError,
    language,
    disabled: defaultDisabled,
    enctype,
    method,
    className,
    style,
    feedback,
    autoStore,
    autoReset,
    storage,
    children,
    translations: customTranslations,
    translator: customTranslator,
    validator: customValidator,
  }) => {

  const element = useRef(null);

  const [fields, setFields] = useState([]);
  const [values, setValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [defaultFields, setDefaultFields] = useState([]);
  const [names, setNames] = useState([]);
  const [messages, setMessages] = useState({});
  const [disabled, setDisabled] = useState(defaultDisabled);
  const [response, setResponse] = useState('');

  const [restored, setRestored] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const {subscribe, emit} = useEventHandler();

  const state = useMemo(() => ({
    values,
    fields,
    messages,
    response,
    disabled,
  }), [values, fields, messages, response, disabled]);

  /**
   * Initialize a field into the form
   * @type function
   * @param id {string} A unique identifier for the field
   * @param name {string} A name with which fields can be grouped.
   * @param value {string} The value of the field.
   * @param fieldRules {string|array<string>} The rules that apply to this field
   * @param isArray {boolean} Whether this field should be treated as an array
   */
  const initialize = useCallback((id, name, value, fieldRules, isArray) => {
    // Add field to names array if it does not already exist there
    if(!names.includes(name)) {
      setNames(prevNames => [...prevNames, name]);
    }
    // Normalize and add rules
    const normalizedRuleList= Array.isArray(fieldRules) ? fieldRules : (fieldRules ? fieldRules.split('|') : []);
    // Add field and default field
    const field = {id, name, value, rules: normalizedRuleList, isArray};
    if(!fields.some(f => f.id === id)){
      setFields(prevFields => ([...prevFields, field]));
      setDefaultFields(prevDefaultField => ([...prevDefaultField, {...field}]));
      emit('init', field);
    }
  }, [names, fields, emit]);

  /**
   * Set the value and default value of the form field or group of form fields
   * @type function
   * @param id {string} The unique identifier of the field group
   * @param value {string} The default value of the field group
   */
  const connect = useCallback((id, value) => {
    const field = fields.find(f => f.id === id);
    const name = field && field.name;
    if(name && (values[name] === undefined && !field.connected)){
      setValues(prevValues => {
        if(field.isArray){
          const currentValues = prevValues[name] || [];
          return {...prevValues, [name]: value !== undefined ? ([...currentValues, value]) : currentValues};
        }else{
          return {...prevValues, [name]: value !== undefined ? value : ''};
        }
      });
      setDefaultValues(prevDefaultValues => {
        if(field.isArray){
          const currentValues = prevDefaultValues[name] || [];
          return {...prevDefaultValues, [name]: value !== undefined ? ([...currentValues, value]) : currentValues};
        }else{
          return {...prevDefaultValues, [name]: value !== undefined ? value : ''};
        }
      });
      field.connected = true;
      emit('connect', field, values);
    }
  }, [values, fields, emit]);

  /**
   * Updates the value of the field group, but leaves the default value alone.
   * @type function
   * @param id {string} The unique identifier of the field group
   * @param value {string} The default value of the field group
   */
  const update = useCallback((id, value) => {
    if (value === undefined) return;
    const field = fields.find(f => f.id === id);
    const name = field && field.name;
    if (name && field.isArray){
      setValues(prevValues => {
        if(prevValues[name] && prevValues[name].includes(value)){
          return {...prevValues, [name]: prevValues[name].filter(el => el !== value)};
        }else{
          return {...prevValues, [name]: [...(prevValues[name] || []), value]};
        }
      });
    }else{
      setValues(prevValues => ({...prevValues, [name]: value}));
    }
  }, [fields]);

  useEffect(() => {
    emit('change', values);
  }, [emit, values]);

  const validate = customValidator || validator;

  const translate = useCallback((errors) => {
    const code = language || 'en';
    const translations = {...(defaultTranslations[code] || {}), ...customTranslations};
    return customTranslator ? customTranslator(errors, translations) : translator(errors, translations);
  },[customTranslator, language, customTranslations]);

  const reset = useCallback(() => {
    window.localStorage.removeItem(storage);
    setFields(JSON.parse(JSON.stringify(defaultFields)));
    setValues(JSON.parse(JSON.stringify(defaultValues)));
    setDisabled(defaultDisabled);
    emit('reset');
    element.current.reset();
  }, [element, defaultDisabled, storage, defaultFields, defaultValues, emit]);

  const handleValidation = useCallback(() => {
    return fields.reduce((errorArray, field) => {
      let outcomes = validate(values[field.name], field.rules, field);
      const errors = outcomes.filter(outcome => !outcome.valid);
      field.valid = outcomes.length === 0 ? undefined : errors.length === 0;
      field.locked = true;
      field.messages = translate(errors);
      return errors.length > 0 ? {...errorArray, [field.name]: translate(errors)} : errorArray;
    }, {});
  }, [fields, values, validate, translate]);

  const handleSuccess = useCallback((response = {}) => {
    if (response.message) {
      setResponse(response.message);
    }
    if (autoReset && !autoStore) reset();
    return onSuccess ? onSuccess(response) : null;
  }, [autoReset, autoStore, onSuccess, reset]);

  const handleError = useCallback((response) => {
    if (response.status !== 422) {
      // Anticipated failed response
      if (response.error) {
        setResponse(response.error);
      }
    } else {
      let responseData = response.response && response.response.data ? response.response.data : response;
      if (responseData.errors) {
        // Validation errors
        setMessages(responseData.errors);
      }
    }
    setDisabled(false);
    return onError ? onError(response) : null;
  }, [onError]);

  const handleSubmit = useCallback((event) => {
    if (event) {
      event.preventDefault();
    }
    setMessages({});
    if (!disabled) {
      setDisabled(true);
      if (shouldValidate) {
        const validationMessages = handleValidation();
        if (Object.keys(validationMessages).length > 0) {
          setMessages(validationMessages);
          if (feedback !== 'full') setDisabled(false);
          emit('validated', state);
          return onValidationError && onValidationError(values, validationMessages);
        }
      }
      emit('validated', state);
      if (onSubmit) {
        const process = onSubmit(values);
        if (process && process.then) {
          process.then(response => {
            if (response.status >= 200 && response.status < 300) {
              emit('success', response);
            } else {
              emit('error', response);
            }
          }).catch(response => {
            emit('error', response);
          });
        } else {
          emit('success', process);
        }
      } else {
        emit('success');
      }
    }
  }, [shouldValidate, onSubmit, onValidationError, feedback, disabled, handleValidation, state, values, emit]);

  /* Store the form on submission to localStorage if asked */

  const store = useCallback(() => {
    if (storage) window.localStorage.setItem(storage, JSON.stringify(state));
  }, [storage, state]);

  const handleStorage = useCallback(() => {
    if(typeof onSave === 'function'){
      try{
        onSave(state);
      } catch(e){
        store();
        console.error("Saved answer to local storage.", e);
      }
    }else if(autoStore) store();
  }, [store, autoStore, onSave, state]);

  const handleRestore = useCallback(state => {
    setValues(state.values);
    setFields(state.fields);
    setMessages(state.messages);
    setResponse(state.response);
    setDisabled(state.disabled);
    setRestored(true);
    emit('restore', state);
  }, [emit]);

  /* Restore the form whenever it is loaded and available */
  const restore = useCallback(() => {
    if (storage && window.localStorage) {
      const stored = window.localStorage.getItem(storage);
      if (stored) {
        const restored = JSON.parse(stored);
        if(restored.fields.length === fields.length) {
          handleRestore(restored);
        }
      }
    }
  }, [storage, fields, handleRestore]);

  /* Build the form context from methods, handlers and data */
  const methods = useMemo(() => ({
    initialize,
    connect,
    update,
    subscribe,
    store,
    restore,
    reset,
  }), [initialize, connect, update, store, subscribe, restore, reset]);

  const handlers = useMemo(() => ({
    handleValidation,
    handleSubmit,
  }), [handleSubmit, handleValidation]);

  const data = useMemo(() => ({
    ...state,
    defaultValues,
    defaultFields,
  }), [state, defaultValues, defaultFields]);

  const context = useMemo(() => ({
    exists: true,
    feedback,
    ...data,
    ...methods,
    ...handlers,
  }), [data, feedback, methods, handlers]);

  useEffect(() => {
    const unsubscribers = [
      subscribe('validated', handleStorage),
      subscribe('success', handleSuccess),
      subscribe('error', handleError),
      subscribe('change', onChange),
      subscribe('restore', onRestore),
    ];
    return () => {
      unsubscribers.forEach(unsubscribe => !!unsubscribe && unsubscribe());
    };
  }, [subscribe, handleStorage, handleSuccess, handleError, onChange, onRestore, onRestored]);

  useEffect(() => {
    if(onLoad) {
      const state = onLoad();
      if(!!state){
        handleRestore(state);
        setLoaded(true);
      }
    }
  }, [onLoad, handleRestore]);

  useEffect(() => {
    if(!restored && !loaded) restore();
  }, [loaded, restored, restore]);

  return (
    <FormContext.Provider value={context}>
      <form action={action}
            className={className}
            style={style}
            method={method}
            ref={element}
            onSubmit={handleSubmit}
            onReset={() => reset()}
            encType={enctype}>
        {((typeof children).toLowerCase() === 'function' && children(context)) || children}
      </form>
    </FormContext.Provider>
  );
};

Form.propTypes = {
  /** Set the amount of feedback that is shown after validation */
  feedback: PropTypes.oneOf(['off', 'standard', 'full']),
  /** Stores the form in local storage when it is submitted (before validation) */
  autoStore: PropTypes.bool,
  /** Resets the form on successful validation and submission */
  autoReset: PropTypes.bool,
  /** An identifier that is used to store the form state */
  storage: PropTypes.string,
  /** Whether the form is disabled or not */
  disabled: PropTypes.bool,
  /** Whether the form should validate the input */
  validate: PropTypes.bool,
  /** The language that is used for the built in translations */
  language: PropTypes.string,
  /** Deprecated: use 'language' instead */
  lang: PropTypes.string,
  /** The method the form should use */
  method: PropTypes.string,
  /** A function that is run when a validation error has occurred */
  onValidationError: PropTypes.func,
  /** Function that is executed when validation succeeds. Can return a Promise. */
  onSubmit: PropTypes.func,
  /** Function that is executed anytime a value changes */
  onChange: PropTypes.func,
  /** Function that is executed when submission was successful (after onSubmit) */
  onSuccess: PropTypes.func,
  /** A function that is run when an error has occurred during onSubmit function */
  onError: PropTypes.func,
};

Form.defaultProps = {
  disabled: false,
  validate: true,
  autoStore: false,
  autoReset: true,
  language: "en",
  method: "POST",
  feedback: "standard",
};

export default Form;
