import React from 'react';
import styles from './Tag.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Tag = props => {

  const handleClick = (e) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <div className={cx({
      base: true,
      clickable: props.onClick,
      'is-primary': props.primary,
    }, props.className)} onClick={handleClick} style={props.style}>
      {props.children}
    </div>
  );
};

export default Tag;
