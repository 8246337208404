import {isValidElement, useCallback, useEffect, useMemo, useState} from 'react';

/**
 * This hook provides the ability to handle an option list.
 *
 * @param options {array} List of options that can be selected, each adhering to option object format
 * @param value {string} The selected value
 * @param placeholder {string} The option that is shown as a placeholder when nothing is selected
 * @param required {boolean} Whether you can set the value to null once you have selected something
 * @param filter {Function} A function that is used to filter the option list
 * @param sort {Function} A function that is used to sort the option list
 * @param onChange {Function} Fires function when changing value
 * @returns {{default: *, search: (...args: any[]) => any, handleClick: handleClick, options: unknown, isSelected: (function(*): boolean), list: *, selected: unknown}}
 */
function useOptionList({options, value, placeholder, required, filter, sort, onChange}){

  const placeholderOption = useMemo(() => ({
    idx: -1,
    label: placeholder,
    thin: true,
    presort: true,
  }), [placeholder]);
  const hasPlaceholder = placeholder && !required;

  // Calculate the options
  const list = useMemo(() => {
    let output = [];
    if(Array.isArray(options) && options.length > 0 && !isValidElement(options[0])){
      output = options;
    }else {
      const children = options;
      const childrenArray = Array.isArray(children) || children === undefined ? children : [children];
      output = childrenArray ? childrenArray.map((option, idx) => {
        return isValidElement(option) ? {
          idx,
          value: option.props.value || option.props.children,
          label: option.props.children,
          disabled: option.props.disabled,
          ...option.props,
        } : undefined;
      }) : [];
    }
    if (hasPlaceholder) {
      output = [placeholderOption, ...output];
    }
    if (sort) {
      output = [
        ...output.filter((option) => option.presort),
        ...output.filter((option) => !option.presort).sort((typeof sort === 'function') ? sort : (a, b) => {
          return (a.label > b.label) ? 1 : -1;
        })];

    }
    return filter ? output.filter(filter) : output;
  }, [hasPlaceholder, filter, options, sort, placeholderOption]);

  // Calculate the correct default option
  const defaultOption = useMemo(() => {
    const basedOnValueProp = list.find((item) => item && item.value === value);
    return basedOnValueProp ? basedOnValueProp : placeholder ? placeholderOption : list[0];
  }, [list, value, placeholder, placeholderOption]);

  const [visibleOptions, setVisibleOptions] = useState(list);


  const [selectedOption, setSelectedOption] = useState(defaultOption || list[0]);

  // Update value when value prop is updated
  useEffect(() => {
    setSelectedOption(defaultOption);
  }, [defaultOption]);

  const handleClick = option => {
    if (option.disabled) return;
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
  };

  // Filter based on search input
  const handleSearch = useCallback((searchValue) => {
    if (!searchValue || (searchValue && searchValue.trim() === '')) {
      setVisibleOptions(list);
    } else if (searchValue) {
      setVisibleOptions(list.filter((option) => {
        return option.label.toString().toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1 || option.persist;
      }));
    }
  }, [list]);

  return {
    default: defaultOption,
    selected: selectedOption,
    search: handleSearch,
    list,
    options: visibleOptions,
    handleClick,
    isSelected: (optionToCheck) => selectedOption.value === optionToCheck.value,
  };


}
export default useOptionList;
