import React from 'react';
import styles from './Banner.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Banner = props => {

  return (
    <div style={props.style} onClick={props.onClick} className={cx('base', {
      'is-error': props.error,
      'is-info': props.info,
      'is-success': props.success,
      'is-warning': props.warning,
      'is-primary': props.primary,
      'is-outline': props.outline,
      'clickable': props.onClick,
      'is-wide': props.wide,
    }, props.className)}>

      <div className={cx('banner-inner', 'inner')}>
        {props.icon &&
        <div className={styles.icon}>
          {props.icon}
        </div>
        }

        <div className={cx('banner-contents', 'contents')}>
          {props.children}
        </div>

        {props.actions &&
        <div className={cx('banner-actions')}>

          {props.actions.map((action, idx) => {
            return React.cloneElement(action, {...action.props, key: idx});
          })}

        </div>
        }
      </div>

    </div>
  );
};

export default Banner;
