import React, {useRef, useState} from 'react';
import styles from './Textarea.module.css';
import classNames from 'classnames/bind';
import {useFormConnect, useOnClickOutside} from '@brainstud/universal-components';

const cx = classNames.bind(styles);

const Textarea = props => {
  const element = useRef({type: "textarea", name: props.name, value: props.value || props.children});
  const [errorMessageToggle, setErrorMessageToggle] = useState(false);
  const {id, active, errors} = useFormConnect(props.name, element, props.rules);

  const toggleErrorMessage = () => {
    setErrorMessageToggle(!errorMessageToggle);
  };
  const hideErrorMessage = () => {
    setErrorMessageToggle(false);
  };
  const errorMessageBox = useRef();
  useOnClickOutside(errorMessageBox, hideErrorMessage);

  return (
    <div className={cx('input', {
      'has-errors': errors.length > 0,
      'is-hidden': props.hidden,
      'is-active': active,
    }, props.className)} style={props.style}>

      {props.label && (
        <label htmlFor={id}>
          <span>{props.label}</span>

          {errors.length > 0 &&
          <div ref={errorMessageBox} className={cx('error-message-toggle')} onClick={toggleErrorMessage}>
            <div className={cx('error-message', {'is-hidden': !errorMessageToggle})}>
              {errors[0]}
            </div>
          </div>
          }
        </label>
      )}

      <textarea name={props.name}
                placeholder={props.placeholder}
                ref={element}
                id={id}
                className={cx({
                  'has-text': (element.current && element.current.value !== ''),
                })}>
        {props.value ? props.value : props.children}
      </textarea>

    </div>
  );
};
export default Textarea;
