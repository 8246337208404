import {useEffect, useState, useCallback} from 'react';

export default function () {

  const [subscriptions, setSubscription] = useState({});
  const [events, setEvents] = useState([]);

  const subscribe = useCallback((event, handler) => {
    if(typeof handler !== "function") return null;
    setSubscription(prevSubscriptions => ({
      ...prevSubscriptions,
      [event]: [...(prevSubscriptions[event] || []), handler],
    }));
    return () => {
      setSubscription(prevSubscriptions => ({
        ...prevSubscriptions,
        [event]: prevSubscriptions[event].filter(anyHandler => anyHandler !== handler),
      }));
    };
  }, []);

  const emit = useCallback((name, ...data) => {
    setEvents(prevEvents => [...prevEvents, {name, data}]);
  }, []);

  useEffect(() => {
    for (let i = 0; i < events.length; i++) {
      const event = events.shift();
      if(subscriptions[event.name]){
        subscriptions[event.name].forEach(handler => handler(...event.data));
      }
    }
  }, [events, subscriptions]);

  return {
    subscribe,
    emit,
  };
}
