import React, {useRef} from 'react';
import classNames from 'classnames/bind';
import styles from './Checkbox.module.css';
import {useFormConnect} from '@brainstud/universal-components';

const cx = classNames.bind(styles);

const Checkbox = (
  {
    name,
    value: defaultValue,
    rules,
    label,
    prefix,
    checked: defaultChecked,
    onClick,
    onMouseOver,
    onFocus,
    className,
    style,
  }) => {

  const checkboxElement = useRef({type: "checkbox", value: defaultValue, checked: !!defaultChecked});

  const {id, errors} = useFormConnect(name, checkboxElement, rules, !!defaultChecked);

  return (
    <label htmlFor={id} className={cx('base', {
      'has-errors': errors.length > 0,
      'is-active': checkboxElement && checkboxElement.current && checkboxElement.current.checked,
      className,
    })} style={style} onClick={onClick} onMouseOver={onMouseOver} onFocus={onFocus}>

      <input
        type="checkbox"
        id={id}
        ref={checkboxElement}
        name={name}
        defaultChecked={defaultChecked}
        defaultValue={defaultValue} />

      {prefix && <strong>{prefix}</strong>}
      {label}
    </label>
  );
};

Checkbox.defaultProps = {
  name: "",
  value: "",
  label: "",
  prefix: "",
  checked: false,
  rules: [],
};

export default Checkbox;
