import React, {useRef, useState} from 'react';
import {useFormConnect,useOnClickOutside} from '@brainstud/universal-components';
import styles from './Input.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Input = props => {

  const element = useRef({type: 'text', value: props.value});
  const {id, isActive, errors} = useFormConnect(props.name, element, props.rules);

  // Error message handling
  const [errorMessageToggle, setErrorMessageToggle] = useState(false);
  const toggleErrorMessage = () => {
    setErrorMessageToggle(!errorMessageToggle);
  };
  const hideErrorMessage = () => {
    setErrorMessageToggle(false);
  };
  const errorMessageBox = useRef();
  useOnClickOutside(errorMessageBox, hideErrorMessage);

  const isHidden = (props.hidden || props.type === 'hidden');

  return isHidden ? (
      <input type="hidden" ref={element} id={id} name={props.name} value={props.value} className={cx(props.className)}/>
    ) : (
      <div className={cx('input', {
        'has-errors': errors.length > 0,
        'is-active': isActive,
        'is-inline': props.size || props.inline,
      }, props.className)} style={{...props.style, width: props.size ? props.size+'%' : undefined}}>
        {props.label && (
          <label htmlFor={id}>
            <span>{props.label}</span>
            {errors.length > 0 &&
            <div ref={errorMessageBox} className={cx('error-message-toggle')} onClick={toggleErrorMessage}>
              <div className={cx('error-message', {'is-hidden': !errorMessageToggle})}>
                {errors[0]}
              </div>
            </div>
            }

          </label>
        )}

        <input name={props.name}
               type={props.type || "text"}
               placeholder={props.placeholder}
               tabIndex={props.tabIndex}
               readOnly={props.readOnly}
               disabled={props.disabled}
               autoFocus={props.autoFocus}
               autoComplete={props.autoComplete}
               onBlur={props.onBlur}
               onFocus={props.onFocus}
               onChange={props.onChange}
               onInput={props.onInput}
               onInvalid={props.onInvalid}
               onReset={props.onReset}
               onSubmit={props.onSubmit}
               onContextMenu={props.onContextMenu}
               ref={element}
               id={id}
               className={cx({
                 'has-text': (element.current && element.current.value !== ''),
               })}/>

      </div>
    );
};
export default Input;
