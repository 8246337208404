import React from 'react';
import styles from './Navigation.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Navigation = props => (
    <nav className={cx('base')}>
      {props.children}
    </nav>
);

export default Navigation;
