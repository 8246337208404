import React from 'react';
import classNames from 'classnames/bind';
import styles from './Content.module.css';

const cx = classNames.bind(styles);

const Content = props => (
  <main className={cx('base', props.className)} style={props.style}>
    {props.children}
  </main>
);

export default Content;
