import React, {useRef} from 'react';
import classNames from 'classnames/bind';
import styles from './Radio.module.css';
import {useFormConnect} from '@brainstud/universal-components';

const cx = classNames.bind(styles);

const Radio = (
  {
    name,
    value: defaultValue,
    rules,
    label,
    prefix,
    checked: defaultChecked,
    onClick,
    onMouseOver,
    onFocus,
    className,
    style,
  }) => {

  const radioElement = useRef({type: "checkbox", value: defaultValue, checked: !!defaultChecked});

  const {id, errors} = useFormConnect(name, radioElement, rules, !!defaultChecked);

  return (
    <label htmlFor={id} className={cx('base', {
      'has-errors': errors.length > 0,
      'is-active': radioElement && radioElement.current && radioElement.current.checked,
      className,
    })} style={style} onClick={onClick} onMouseOver={onMouseOver} onFocus={onFocus}>

      <input
        type="radio"
        id={id}
        ref={radioElement}
        name={name}
        defaultChecked={defaultChecked}
        defaultValue={defaultValue} />

      {prefix && <strong>{prefix}</strong>}
      {label}
    </label>
  );
};

Radio.defaultProps = {
  name: "",
  value: "",
  label: "",
  prefix: "",
  checked: false,
  rules: [],
};

export default Radio;
