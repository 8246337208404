import React from "react";
import styles from "./Card.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const CardContext = React.createContext();

function Card(props) {
  const {
    children,
    style,
    className,
    focusable,
    hoverable,
    locked,
    flat,
    selectable,
    selected,
    deactivated,
    disabled,
    onClick,
  } = props;

  const clickable = Boolean(onClick);

  return (
    <div
      className={cx(
        "base",
        {
          clickable,
          hoverable,
          focusable: focusable || clickable,
          selectable,
          "is-locked": locked,
          "is-flat": flat,
          "is-selected": selected,
          "is-disabled": deactivated || disabled,
        },
        className
      )}
      style={style}
      tabIndex={clickable || focusable ? 0 : -1}
      onClick={onClick}
      onKeyUp={e => {
        if (e.keyCode === 13) onClick();
      }}
    >
      <CardContext.Provider value={props}>
          {children}

          {locked && <div className={styles.locker}>{locked}</div>}
      </CardContext.Provider>
    </div>
  );
}

export default Card;
export { CardContext };
