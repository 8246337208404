import React, {useEffect, useRef, useState, useCallback} from 'react';
import styles from './Dropdown.module.css';
import classNames from 'classnames/bind';
import propTypes from 'prop-types';
import {useFormConnect, useOnClickOutside, useOptionList} from '@brainstud/universal-components';

const cx = classNames.bind(styles);

const Dropdown = (
  {
    name,
    label,
    placeholder,
    rules,
    children,
    filter,
    options: optionsArray,
    sort,
    opened,
    value,
    onChange: changeHandler,
    searchable,
    small,
    disabled,
    block,
    className,
    style,
  }) => {

  const [isOpen, setOpen] = useState(opened);
  const onChange = option => {
    setOpen(false);
    changeHandler(option);
  };

  const rulesArray = typeof rules === 'string' ? rules.split('|') : rules;
  const required = Array.isArray(rulesArray) && rulesArray.includes('required');

  //  Use an Option List to handle selection, filtering, searching and sorting of the option list.
  const {search, handleClick, selected, options, isSelected, list} = useOptionList({
    placeholder, filter, sort, value, onChange, required,
    options: optionsArray || children,
  });
  const toggle = useCallback((to) => setOpen((to === true || to === false) ? to : !isOpen), [isOpen]);
  const isDisabled = disabled || (list.length <= 1);

  // Scrolls to the selected item
  const drawer = useRef();
  const selectedElement = useRef();
  useEffect(() => {
    if (isOpen && drawer.current && selectedElement.current) {
      drawer.current.scrollTop = selectedElement.current.offsetTop - 140;
    }
  }, [isOpen]);

  // Connect the dropdown to the form
  // FixMe: Currently this input is not resetted to default when the form is reset.
  const inputElement = useRef({name, value: selected.value});
  const {id, triggerUpdate, hasErrors} = useFormConnect(name, inputElement, rules);
  useEffect(() => {
    triggerUpdate();
  }, [triggerUpdate, selected]);

  // Calculate whether to show the options above or below the input
  const dropdown = useRef();
  const spaceBelow = window.innerHeight - (dropdown.current ? dropdown.current.getBoundingClientRect().bottom : 0);
  const upsideDown = (spaceBelow < Math.min(320, (options.length + 1) * 40));

  // Close dropdown on click outside
  useOnClickOutside(dropdown, () => {
    toggle(false);
  });

  // Reset search on close
  useEffect(() => {
    if(!isOpen) search();
  }, [search, isOpen]);


  return (
    <div ref={dropdown}
         className={cx('base', {
           isOpen,
           'has-errors': hasErrors,
           small,
           disabled,
           block,
         }, className)}
         style={style}>
      {name && <input type="hidden" name={name} value={selected.value || ""} ref={inputElement} />}
      {label && <label id={`${id}_label`} htmlFor={id}>{label}</label>}
      <button type="button"
              id={id}
              aria-haspopup="listbox"
              aria-labelledby={label ? `${id}_label ${id}` : id}
              aria-expanded={isOpen}
              disabled={isDisabled}
              onClick={toggle}>
        <span>{selected.label || selected.value}</span>
        <svg className={cx('chevron-down')} focusable="false" viewBox="0 0 24 24"
             aria-hidden="true" role="presentation">
          <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
        </svg>
      </button>
      <ul ref={drawer}
          tabIndex={-1}
          role="listbox"
          style={{transform: (upsideDown) ? 'translateY(-100%)' : null}}
          className={cx({
        visible: isOpen,
        upsideDown,
      })}>
        {searchable && isOpen && (
          <li key={-1} className={cx('thinnest', 'unpad')}>
            <input className={cx('search-input')}
                   autoFocus={true}
                   onKeyUp={event => search(event.currentTarget.value)}
                   type="search"/>
          </li>
        )}
        {options.map((option, idx) => (
          <li
            key={idx}
            tabIndex={!option.disabled ? 0 : -1}
            ref={isSelected(option) ? selectedElement : undefined}
            role="option"
            aria-selected={isSelected(option)}
            className={cx({
              disabled: option.disabled,
              small,
              selected: isSelected(option),
            })}
            onKeyPress={(e) => {
              if (e.charCode === 13) handleClick(option);
            }}
            onClick={() => handleClick(option)}>
            {isSelected(option) &&
            <svg xmlns="http://www.w3.org/2000/svg" className={cx('check')} viewBox="0 0 24 24">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
            </svg>
            }
            {option.label || option.value}
          </li>
        ))}
      </ul>
    </div>
  );
};

Dropdown.defaultProps = {
  rules: [],
  opened: false,
  searchable: false,
  disabled: false,
  sort: false,
  filter: false,
  onChange: () => {},
};

Dropdown.propTypes ={
  label: propTypes.string,
  placeholder: propTypes.string,
  rules: propTypes.oneOfType([propTypes.array, propTypes.string]),
  options: propTypes.array,
  opened: propTypes.bool,
  searchable: propTypes.bool,
  filter: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  sort: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  onChange: propTypes.func,
};

export default Dropdown;
