import React from 'react';
import styles from './Button.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Button = props => {

  const isLink = (props.href || props.file || props.download || props.to);
  const classList = cx('base', {
    'is-link': props.link,
    'is-button': !props.link,
    'is-round': props.round,
    'is-error': props.error,
    'is-info': props.info,
    'is-warning': props.warning,
    'is-alternative': props.alternative,
    'is-success': props.success,
    'is-white': props.white,
    'is-outline': props.outline,
    'is-loading': props.loading,
    'is-transparent': props.transparent,
    'is-disabled': props.disabled,
    'is-block': props.block,
    'is-small': props.small,
  }, props.className);

  return (
    <React.Fragment>
      {(isLink &&
        <a href={props.href || (props.to ? `#${props.to}` : false)}
           download={(props.file || props.download)}
           target={props.target}
           style={props.style}
           onClick={!props.disabled ? props.onClick : (e) => {e.preventDefault(); return false;}}
           className={classList}>
          {props.children}
        </a>
      )}
      {(!isLink &&
        <button type={props.type}
                disabled={props.disabled}
                style={props.style}
                onClick={props.onClick}
                className={classList}>
          {props.children}
        </button>
      )}
    </React.Fragment>
  );
};

export default Button;
