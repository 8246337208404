import React from 'react';
import classNames from 'classnames/bind';
import styles from './Grid.module.css';

const cx = classNames.bind(styles);

const Grid = props => {

  return (
    <div style={props.style}
         className={cx('base', props.className)}>
      {props.children}
    </div>
  );
};

export default Grid;
