import React, { useContext } from "react";
import { CardContext } from "./Card";
import styles from "./Card.module.css";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

function CardMedia(props) {
  const {
    children,
    image,
    color,
    className,
    style,
    video,
    ratio,
    onClick,
  } = props;

  const cardProps = useContext(CardContext);

  const backgroundStyle = {
    backgroundImage: image ? `url(${image})` : "",
    backgroundColor: color,
  };

  return (
    <div
      className={cx("media", {}, className)}
      style={{ paddingBottom: `${ratio}%`, ...style }}
      onClick={onClick}
    >
      {video && (
        <div className={styles.mediaVideo}>
          <video autoPlay loop src={video} />
        </div>
      )}
      <div className={styles.mediaBackground} style={backgroundStyle}>
        {children}
      </div>
    </div>
  );
}

export default CardMedia;
