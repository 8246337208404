import React from 'react';
import classNames from 'classnames/bind';
import styles from './Panel.module.css';

const cx = classNames.bind(styles);

const Panel = props => {

    return (
        <div className={cx('base','panel-component', props.className)} style={props.style}>
          {props.children}
        </div>
    );
};

export default Panel;
