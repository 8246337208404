import React from 'react';
import styles from './Breadcrumbs.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const BreadCrumbs = props => {

  return (
    <div className={cx('base')} style={props.style}>
      {props.children}
    </div>
  );
};

const Crumb = props => {
  return (
    <div onClick={props.onClick}
         className={cx('crumb', {
           'is-active': props.active,
           'is-clickable': props.onClick,
         })}>
      {props.children}
    </div>
  );
};
Crumb.displayName = 'Crumb';

export {Crumb, BreadCrumbs};
